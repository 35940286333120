import React from 'react';
import Box from './Box';

function Scoring(props) {
    return (
        <Box className="text-center">
            <details>
                <summary className='flex items-center gap-[10px] justify-center font-normal text-white text-[32px] font-russo'>
                    Scoring
                </summary>
                <p>1 kill = 1 point</p>
                <hr className='border-secondary m-auto my-[10px] w-4/5' />
                <p>top 1: 12 points</p>
                <p>top 2: 9 points</p>
                <p>top 3: 7 points</p>
                <p>top 4: 5 points</p>
                <p>top 5: 4 points</p>
                <p>top 6: 3 points</p>
                <p>top 7: 3 points</p>
                <p>top 8: 2 points</p>
                <p>top 9: 2 points</p>
                <p>top 10: 2 points</p>
                <p>top 11: 1 points</p>
                <p>top 12: 1 points</p>
                <p>top 13: 1 points</p>
                <p>top 14: 1 points</p>
                <p>top 15: 1 points</p>
                <p>top 16: 0 points</p>
                <p>top 17: 0 points</p>
                <p>top 18: 0 points</p>
                <p>top 19: 0 points</p>
                <p>top 20: 0 points</p>
            </details>
        </Box>
    );
}

export default Scoring;