import { useEffect, useState } from "react";
import useFetchData from "../useFetchData";

const useTeam = (id, dependencies) => {
	const { data, error, fetchGETData } = useFetchData('team');
	const [team, setTeam] = useState({})

	useEffect(() => {
		if(!id) return
		fetchGETData({id})
	}, [fetchGETData, id, ...dependencies])
	
	useEffect(() => setTeam(data?.data), [setTeam, data?.data])

	return [team, error.code === 404]
};

export default useTeam;