import { useEffect, useState } from "react";
import useFetchData from "../useFetchData";

const useTournamentGroups = (id, round, dependencies) => {
	const { data, error, fetchGETData } = useFetchData('tournament/matches');
    const [tournament, setTournamentTeams] = useState([])

	useEffect(() => {
		if(!id) return
		fetchGETData({id, round})
	}, [fetchGETData, id, ...dependencies])

    useEffect(() => setTournamentTeams(data?.data), [setTournamentTeams, data?.data])

	return [tournament, error?.code === 404]
};

export default useTournamentGroups;