import Profile from '../page/profile/Profile';

const routes = [
    {
        path: '/profile',
        element: <Profile /> ,
    },
    {
        path: '/profile/:id',
        element: <Profile/>
    }
]

export default routes;