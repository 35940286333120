import { action } from 'easy-peasy';

const user = {
    data: localStorage.getItem('user') === null ? null : JSON.parse(localStorage.getItem('user')),
    setUserData: action((state, payload) => {
        state.data = payload;
        localStorage.setItem('user', JSON.stringify(payload))
    }),
    clear: action((state, payload) => {
        state.data = {};
        localStorage.removeItem('user')
        localStorage.removeItem('token')
    }),

    token: localStorage.getItem('token'),
    setToken: action((state, payload) => {
        state.token = payload
        localStorage.setItem('token', payload ?? "")
    }),

    logged: JSON.parse(localStorage.getItem('logged')),
    setLogged: action((state, payload) => {
        state.logged = payload;
        localStorage.setItem('logged', JSON.stringify(payload))
    }),
};

export default user;
