import React from 'react';

function Selector(props) {
    return (
        <select {...props ?? []} className={props.className + ' border-[2px] text-[14px] focus:border-primary outline-none border-secondary bg-background-light w-full p-[15px] rounded-[25px] transition text-white'}>
            {props.options.map((o, i) => {
                if(props.optionsName){
                    if(props.defaultValue === o) return <option value={o} selected>{props.optionsName[i]}</option>
                    else return <option value={o}>{props.optionsName[i]}</option>
                }else{
                    if(props.defaultValue === o) return <option value={o} selected>{o}</option>
                    else return <option value={o}>{o}</option>
                }
            }
                
            )}
        </select>
    );
}

export default Selector;