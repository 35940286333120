import Root from '../page/Root';
import Homepage from '../page/Homepage';
import NotFound from '../page/NotFound';
import Login from '../page/Login';
import Signin from '../page/Signin';
import Logout from '../page/logout';
import Profile from './profile';
import PersistantUser from '../components/PersistantUser';
import Team from './team';
import Tournament from './tournament';
import { createBrowserRouter } from 'react-router-dom';
import ComingSoon from '../page/ComingSoon';

const router = createBrowserRouter([
  {
    path: '/',
    element: <PersistantUser />,
    children: [
    {
      path: '/',
      element: <Root />,
      children: [
        {
          path: '*',
          element: <NotFound />
        },
        {
          path: '/',
          element: <Homepage />
        },
        {
          path: '/login',
          element: <Login />
        },
        {
          path: '/dropmap',
          element: <ComingSoon />
        },
        {
          path: '/ranking',
          element: <ComingSoon />
        },
        {
          path: '/signin',
          element: <Signin />
        },
        {
          path: '/logout',
          element: <Logout />
        },
        ...Profile,
        ...Team,
        ...Tournament
      ]
    }
    ]
  }])
  
export default router;