import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { useNavigate } from 'react-router-dom';
import Banner from '../../components/Banner';
import Box from '../../components/Box';
import Button from '../../components/Button';
import {ReactComponent as Twitter} from '../../assets/twitter.svg';
import {ReactComponent as Instagram} from '../../assets/instagram.svg';
import {ReactComponent as Twitch} from '../../assets/twitch.svg';
import {ReactComponent as IconPlayer} from '../../assets/player.svg';
import Logo from '../../assets/logo.png';
import Input from '../../components/Input';
import { Link, useParams } from 'react-router-dom';
import TwitchChannelStream from '../../components/TwitchChannelStream';
import ReactCountryFlag from 'react-country-flag';

import useTeam from '../../api/team/useTeam';
import useTeamPlayers from '../../api/team/useTeamPlayers';
import useFetchData from '../../api/useFetchData';
import Modal from '../../components/Modal';
import CountrySelector from '../../components/CountrySelector';
import { useState } from 'react';
import useTeamTournaments from '../../api/team/useTeamTournaments';
import { useEffect } from 'react';

function CustomButtons({url, icon}) {
	const Icon = icon
	return (
		<a className="flex justify-center items-center w-[60px] h-[60px] rounded-[30px] bg-primary text-black" href={url}><Icon/></a>
	)
}

function Team() {
	let navigate = useNavigate();
	const { t } = useTranslation();

	const userSession = useStoreState(state => state.user.data)
	const { data: dataEditTeamLoad, fetchPOSTData: editTeamAPI } = useFetchData('team/edit');

	const id = parseInt(useParams()?.id ?? userSession?.team);

	const [Team, notFound] = useTeam(id, [navigate, dataEditTeamLoad])

	const PlayersList = useTeamPlayers(id, [navigate])

	const { fetchGETData: deleteTeam } = useFetchData('team/delete');
	const { fetchGETData: leaveTeam } = useFetchData('team/leave');
	const { fetchPOSTData: invitePlayerAPI } = useFetchData('team/invite');
	const { data, fetchGETData: tournamentStatisticsAPI } = useFetchData('tournament/statistics');
	
	const tournaments = useTeamTournaments(id, [navigate]);

	const [showDeleteTeam, setShowDeleteTeam] = useState(false)
	const [showLeaveTeam, setShowLeaveTeam] = useState(false)
	const [showEditTeam, setShowEditTeam] = useState(false)
	const [showInvitePlayer, setShowInvitePlayer] = useState(false)

	const editTeam = (ev) => {
        const inputs = ev.target.parentNode.getElementsByTagName("input")
		const selectors = ev.target.parentNode.getElementsByTagName("select")
        editTeamAPI(
			{
				id, 
				name: inputs["name"].value, 
				image: inputs["image"].value, 
				flag: selectors["flag"].value,
				description: inputs["description"].value,
				twitch: inputs["twitch"].value, 
				twitter: inputs["twitter"].value, 
				instagram: inputs["instagram"].value
			}
		)
    }

	const invitePlayer = (ev) => {
        const inputs = ev.target.parentNode.getElementsByTagName("input")
        invitePlayerAPI(
			{
				id, 
				email: inputs["email"].value
			}
		)
		
		for(let i = 0; i < inputs.length; i++) {
			inputs[i].value = ""
		}
    }

	let tournamentStats = [];

	useEffect(() => {
		tournaments?.forEach((t) => {
			tournamentStatisticsAPI({id: t?.id})
		})
	}, [tournaments])

	useEffect(() => {
		tournamentStats.push(Object.values(data?.data ?? {})?.find(t => t.team === id))
		console.log(tournamentStats)
	}, [data])

	

	return (
		<>
		<Banner>Team Page</Banner>
		<div className='flex flex-col gap-[20px] w-[80%] mx-auto'>
			<Box className="h-[450px] !flex-row !p-0">
				<div className='h-full max-w-[450px] w-screen relative flex justify-center items-center profile-diagonal'>
					<div className='left-[20%] h-[calc(80%_-_20px)] aspect-square relative z-10'>
						<img className='left-[10px] top-[10px] object-contain absolute aspect-square h-[calc(100%_-_20px)]' 
							src={Team?.image !== "" ? Team?.image : Logo}
						/>
					</div>
				</div>
				<div className='flex flex-col justify-between grow mx-[20%] my-[5%]'>
					<div>
						<div>
							<h1 className="text-primary font-russo text-[32px] font-[400]">{Team?.name} <ReactCountryFlag countryCode={Team?.information?.flag} /></h1>
						</div>
						<br />
						<h2 className="text-white text-[24px] font-[700]">{Team?.information?.slot}</h2>
					</div>
					<p className='text-white text-justify'>
						{Team?.information?.description}
					</p>
					<div className='flex gap-[20px]'>
						{(Team?.information?.twitter) && <CustomButtons icon={Twitter} url={`https://twitter.com/${Team.information?.twitter}`}/> }
						{(Team?.information?.instagram) && <CustomButtons icon={Instagram} url={`https://www.instagram.com/${Team.information?.instagram}`}/> }
						{(Team?.information?.twitch) && <CustomButtons icon={Twitch} url={`https://www.twitch.tv/${Team.information?.twitch}`}/> }
					</div>
				</div>
				
			</Box>
			<div className='flex gap-[20px]'>
				<Box>

				</Box>
				<Box>

				</Box>
				<Box>

				</Box>
				<Box>

				</Box>
			</div>
			<div className='grid grid-cols-[2fr_1fr] gap-[20px]'>
				<div className='flex flex-col gap-[20px]'>
					{(Team?.information?.twitch) && <Box className="!py-[0px]">
						<TwitchChannelStream channel={Team?.information.twitch} />
					</Box>}
					<Box>
						{tournaments?.map(t => <Link to={`/tournament/${t.id}`}>
						{JSON.stringify(t)}
						</Link>)}
					</Box>
					<Box>

					</Box>
				</div>
				<div className='flex flex-col gap-[20px]'>
					<Box>
						<h1 className='flex items-center gap-[10px] justify-center font-normal text-white text-[32px] font-russo'><IconPlayer className='w-[24px] h-[24px] text-primary'/>Roster</h1>
						{
							PlayersList?.map((player) => 
							<div className='mx-[20px]'>
								<Link className="flex gap-[20px] items-center" to={"/profile/"+player.id}>
									<img className="w-[50px] aspect-square object-cover" src={player?.image ? player?.image : Logo} alt="profile"/>
									<p className='text-white'>{player.username} {player.information?.slot && `(${player.information?.slot})`}</p>
								</Link>
							</div>
							)
						}
						{userSession?.id === Team?.owner &&
						<>
							<Button onClick={() => setShowInvitePlayer(true)}>Invite player</Button>
							<Modal show={showInvitePlayer} title={"Invite player"} onClose={() => setShowInvitePlayer(false)} action={invitePlayer} actionName="Invite">
								<div className='flex flex-col gap-[10px] w-full'>
									<h1 className='font-medium text-text'>Email of invited player</h1>
									<Input name="email" type="text"></Input>
								</div>
							</Modal>
						</>
						}
					</Box>

					{
						(PlayersList?.find(u => u.id === userSession?.id) && userSession?.id !== Team?.owner) &&
						<>
							<Button red onClick={() => setShowLeaveTeam(true)}>Leave team</Button>
							<Modal show={showLeaveTeam} title={"Leave team"} onClose={() => setShowLeaveTeam(false)} action={() => {leaveTeam({id}); navigate('/teams/')}} actionName="Leave" urgent>
								Are you sure you want to leave the team "{Team?.name}" ?
							</Modal>
						</>
					}
					{userSession?.id === Team?.owner
					&& <>
						<Button onClick={() => setShowEditTeam(true)}>
							Edit team
						</Button>
						<Modal show={showEditTeam} title="Edit team" onClose={() => setShowEditTeam(false)} action={editTeam} actionName="Edit">
							<div className='flex flex-col gap-[10px] w-full'>
								<h1 className='font-medium text-text'>Name of the team</h1>
								<Input name="name" type="text" defaultValue={Team?.name}></Input>
							</div>
							<div className='flex flex-col gap-[10px] w-full'>
								<h1 className='font-medium text-text'>Link of the image</h1>
								<Input name="image" type="text" defaultValue={Team?.image}></Input>
							</div>
							<div className='flex flex-col gap-[10px] w-full'>
								<h1 className='font-medium text-text'>Flag</h1>
								<CountrySelector name="flag" defaultValue={Team?.information?.flag}/>
							</div>
							<div className='flex flex-col gap-[10px] w-full'>
								<h1 className='font-medium text-text'>Description</h1>
								<Input name="description" type="text" defaultValue={Team?.information?.description}></Input>
							</div>
							<div className='flex flex-col gap-[10px] w-full'>
								<h1 className='font-medium text-text'>Twitter</h1>
								<Input name="twitter" type="text" defaultValue={Team?.information?.twitter}></Input>
							</div>
							<div className='flex flex-col gap-[10px] w-full'>
								<h1 className='font-medium text-text'>Instagram</h1>
								<Input name="instagram" type="text" defaultValue={Team?.information?.instagram}></Input>
							</div>
							<div className='flex flex-col gap-[10px] w-full'>
								<h1 className='font-medium text-text'>Twitch</h1>
								<Input name="twitch" type="text" defaultValue={Team?.information?.twitch}></Input>
							</div>
						</Modal>

						<Button red onClick={() => setShowDeleteTeam(true)}>Delete team</Button>
						<Modal show={showDeleteTeam} title={"Delete team"} onClose={() => setShowDeleteTeam(false)} action={() => {deleteTeam({id}); navigate('/teams/')}} actionName="Delete" urgent>
							Are you sure you want to delete the team "{Team?.name}" ?
						</Modal>
					</>
					}
				</div>
			</div>
		</div>
		
		</>
	);
}

export default Team;
