import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
 
import en from "./en/translations.json";

i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
  fallbackLng: 'en',
  resources: {
    en: {translation: en}
   },
   keySeparator: ".",
   interpolation: {
      escapeValue: false
    }
 });

export default i18n