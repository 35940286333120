import { useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import useFetchData from './useFetchData';
import { useNavigate } from 'react-router-dom';

const useUserData = () => {
    const { data, error, fetchGETData } = useFetchData('account/auth');

    const setUserData = useStoreActions((action) => action.user.setUserData)
    const clearData = useStoreActions((action) => action.user.clear)

    let location = useNavigate();

    useEffect(() => {
        fetchGETData()
    }, [location, fetchGETData])

    useEffect(() => {
        if (error.code === 404) {
            clearData()
            console.log('token cleared');
            return
        }
        if(!data) return
        setUserData(data?.data)
        localStorage.setItem('user', JSON.stringify(data?.data))
    }, [data, error.code, setUserData, clearData])
};

export default useUserData;