import { useEffect, useState } from "react";
import useFetchData from "../useFetchData";

const useTeamTournaments = (id, dependencies) => {
	const { data, error, fetchGETData } = useFetchData('team/tournaments');
	const [tournaments, setTournaments] = useState([])

	useEffect(() => {
		if(!id) return
		fetchGETData({id})
	}, [fetchGETData, id, ...dependencies])
	
	useEffect(() => setTournaments(data?.data), [setTournaments, data?.data])

	return tournaments
};

export default useTeamTournaments;