import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import Banner from '../../components/Banner';
import Box from '../../components/Box';
import Button from '../../components/Button';
import { Link, useParams } from 'react-router-dom';
import BannerTournament from './parts/banner';
import Scoring from '../../components/Scoring';
import useFetchData from '../../api/useFetchData';

import useTournament from '../../api/tournament/useTournament';
import useTournamentTeams from '../../api/tournament/useTournamentTeams';
import useUser from '../../api/user/useUser';
import { useEffect } from 'react';
import useTournamentGroups from '../../api/tournament/useTournamentGroups';
import useTeamPlayers from '../../api/team/useTeamPlayers';
import Selector from '../../components/Selector';

function PlayerSide() {
	let navigate = useNavigate();
	const { t } = useTranslation();

	const id = parseInt(useParams()?.id);
	const userSession = useStoreState((state) => state.user.data);

	const { data: loadAdd, fetchPOSTData: addPlayerAPI } =
		useFetchData('tournament/add');
	const { data: loadRemove, fetchPOSTData: removePlayerAPI } =
		useFetchData('tournament/remove');
	const { data: loadSpawn, fetchPOSTData: setSpawnAPI } =
		useFetchData('tournament/spawn');

	const [Tournament] = useTournament(id, [navigate, loadAdd, loadRemove]);
	const [TournamentTeams] = useTournamentTeams(id, [
		navigate,
		loadAdd,
		loadRemove,
		loadSpawn
	]);
	const [Groups] = useTournamentGroups(id, [navigate, loadAdd, loadRemove]);
	const players = useTeamPlayers(userSession?.team, [
		navigate,
		loadAdd,
		loadRemove,
		loadSpawn
	]);

	const TeamGroup = TournamentTeams?.find(
		(t) => t.team.id === userSession?.team
	);
	const Group = Groups?.find((g) => g.id === TeamGroup?.group);

	const [Author, AuthorNotFound] = useUser(Tournament?.owner, [navigate]);

	useEffect(() => {
		if (!Author || Object.keys(Author).length === 0) return;
		if (
			!(
				TournamentTeams?.find(
					(t) => t.team.owner === userSession?.id
				) || Tournament?.players?.find((u) => u === userSession?.id)
			)
		)
			navigate(`/tournament/${id}`);
	}, [TournamentTeams, userSession, Author, navigate, id]);

	const addPlayer = (player) => {
		addPlayerAPI({
			tournament_id: id,
			team_id: player?.team,
			user_id: player?.id,
		});
	};

	const removePlayer = (player) => {
		removePlayerAPI({
			tournament_id: id,
			team_id: player?.team,
			user_id: player?.id,
		});
	};
	const setSpawnStorm = (ev) => {
		const select = ev.target.parentNode.getElementsByTagName('select')[0]
		let value = select.options[select.selectedIndex].value

		setSpawnAPI({
			tournament_id: id,
			team_id: userSession?.team,
			spawn_storm: value,
		})
	}

	const setSpawnWorld = (ev) => {
		const select = ev.target.parentNode.getElementsByTagName('select')[0]
		let value = select.options[select.selectedIndex].value

		setSpawnAPI({
			tournament_id: id,
			team_id: userSession?.team,
			spawn_world: value,
		})
	}

	return (
		<>
			<Banner>Manage tournament</Banner>
			<div className="flex flex-col gap-[20px] w-[80%] mx-auto">
				<BannerTournament Tournament={Tournament} />
				<div className="flex gap-[20px]">
					<Box className="flex justify-center items-center flex-col"></Box>
					<Box className="flex justify-center items-center flex-col">
						<h1 className="font-normal text-white text-[1.5em] font-russo">
							Tournament by
						</h1>
						<Link
							to={`/profile/${Author?.id}`}
							className="text-primary underline"
						>
							{Author?.username}
						</Link>
					</Box>
					<Box className="flex justify-center items-center flex-col">
						<h1 className="font-normal text-white text-[1.5em] font-russo">
							{Tournament?.cashprize?.total}$
						</h1>
					</Box>
					<Box className="flex justify-center items-center flex-col"></Box>
				</div>
				<div className="grid grid-cols-[2fr_1fr] gap-[20px]">
					<div className="flex flex-col gap-[20px]">
						<Box>
							<h1 className="font-normal text-white text-[2.5em] font-russo">
								Team number {TeamGroup?.number}
							</h1>
							<h1 className="font-normal text-white text-[1.5em] font-russo">
								Join key: {Group?.joinKey}
							</h1>
						</Box>
						{TournamentTeams?.find(
							(t) => t.team.owner === userSession?.id
						) && (
							<Box>
								<h1 className="font-normal text-white text-[1.5em] font-russo">
									Manager part
								</h1>
								<div className='flex'>
									<div>
										<Selector id="spawn-storm-point"
											options={[
												'Wall',
												'Ferrari',
												'North Pad',
												'Beast',
												'Checkpoint',
												'Mill',
												'Cascades',
												'Command',
												'Highpoint',
												'Stormcatcher',
												'Thunderwatch',
												'Lightning Rod',
												'IMC',
												'Cascade Falls',
												'Jurassic',
												'Antenna',
												'Barometer',
												'Cenote',
												'God Cave',
												'ShipFall',
												'Gale',
												'Launch Pad',
												'Fish Farms',
											]}
											defaultValue={TeamGroup?.spawnStorm}
										/>
										<Button onClick={setSpawnStorm}>Set spawn on Storm Point</Button>
									</div>
									<div>
										<Selector id="spawn-world-edge"
											options={[
												'Trials',
												'Sky West',
												'Sky East',
												'Survey',
												'Epicenter',
												'Climatizer',
												'Countdown',
												'Fissure',
												'Monument',
												'Fragment',
												'Overlook',
												'Landslide',
												'Mirage',
												'Staging',
												'Harvester',
												'Siphon',
												'Geyser',
												'Maude',
												'Stacks',
												'Dome',
												'Launch Site',
												'Tree',
												'Thermal',
											]}
											defaultValue={TeamGroup?.spawnWorld}
										/>
										<Button onClick={setSpawnWorld}>Set spawn on World Edge</Button>
									</div>
								</div>
									
								<div className="flex flex-col gap-[20px]">
									{players?.map((player) => (
										<div className="flex">
											<h1>{player?.username}</h1>
											{Tournament?.players?.find(
												(p) => p === player.id
											) ? (
												<Button
													red
													onClick={() =>
														removePlayer(player)
													}
												>
													Remove
												</Button>
											) : (
												<Button
													onClick={() =>
														addPlayer(player)
													}
												>
													Add
												</Button>
											)}
										</div>
									))}
								</div>
							</Box>
						)}
					</div>
					<div className="flex flex-col gap-[20px]">
						{/* <Box>
						<h1 className='flex items-center gap-[10px] justify-center font-normal text-white text-[32px] font-russo'>
							<IconPlayer className='w-[24px] h-[24px] text-primary'/>{TournamentTeams?.length} Teams ({TournamentTeams?.filter(t => t.checked).length} checked)
						</h1>
					</Box> */}
						<Scoring />
						<Button to={`/tournament/${id}`}>Back</Button>
					</div>
				</div>
			</div>
		</>
	);
}

export default PlayerSide;
