import React from 'react';
import { Link } from 'react-router-dom';

export default function Button(props) {
    let className = "bg-primary hover:bg-primary-light text-black text-center transition px-[80px] py-[20px] font-400 font-russo text-[16px] uppercase"
    if(props.red) className = className.replace('bg-primary hover:bg-primary-light text-black', 'bg-[#df0606] hover:bg-[#b31111] text-white')
  return (
    <>
    { props.onClick ? 
        <button onClick={props.onClick} className={className}>{props.children}</button>
    :
        <Link className={className} to={props.to}>{props.children}</Link>
    }
    </>
    )
}
