import Tournament from '../page/tournament/Tournament';
import Manage from '../page/tournament/Manage';
import PlayerSide from '../page/tournament/PlayerSide';
import List from '../page/tournament/List';

const routes = [
    {
        path: '/tournaments/',
        element: <List />,
    },
    {
        path: '/tournament/:id',
        element: <Tournament />
    },
    {
        path: '/tournament/:id/round/:round',
        element: <Tournament />
    },
    {
        path: '/tournament/:id/manage',
        element: <Manage />
    },
    {
        path: '/tournament/:id/play',
        element: <PlayerSide />
    }
]

export default routes;