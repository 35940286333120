import React, { useState, useMemo } from 'react'
import Selector from './Selector'
import countryList from 'react-select-country-list'
import ReactCountryFlag from "react-country-flag"

function CountrySelector(props) {
  const options = useMemo(() => countryList().getData(), [])

  return <>
  <Selector name={props.name} defaultValue={props.defaultValue} options={options.map(country => country.value)} />
  </>
}

export default CountrySelector