import React from 'react';
import Box from './Box';
import Button from './Button';

export default function Modal({ title, action, actionName, urgent, children, show, onClose }) {
    let className = "z-50 fixed left-0 top-0 right-0 bottom-0 bg-[#000000B0] flex justify-center items-center opacity-0 pointer-events-none transition-all"
    if(show) {
        className += " !opacity-100 !pointer-events-auto"
        document.body.style.overflow = 'hidden'
    }
    else{
        document.body.style.overflow = '';
    }

	return (
		<div className={className} onClick={() => onClose()}>
			<Box className="w-9/12 flex flex-col gap-[20px] p-[20px] max-h-full overflow-auto" onClick={e => e.stopPropagation()}>
                <h1 className='text-white font-normal font-russo text-[1.5rem]'>{title}</h1>
                <hr className='border-secondary m-auto my-[10px] w-4/5' />
                <div>
                    {children}
                </div>
                <hr className='border-secondary m-auto my-[10px] w-4/5' />
                { urgent 
                    ?<Button red onClick={(ev) => {action(ev); onClose()}}>{actionName}</Button>
                    :<Button onClick={(ev) => {action(ev); onClose()}}>{actionName}</Button>
                }
            </Box>
		</div>
    )
}
