import Team from '../page/team/Team';
import List from '../page/team/List';

const routes = [
    {
        path: '/teams/',
        element: <List />,
    },
    {
        path: '/team/',
        element: <Team />
    },
    {
        path: '/team/:id',
        element: <Team />
    }
]

export default routes;