import React from 'react';

function Box(props) {
    return (
        <div {...props} className={'text-white flex flex-col py-[20px] w-full rounded-[5px] bg-background-light border-secondary border-[1px] '+ props.className ?? null}>
            {props.children}
        </div>
    );
}

export default Box;