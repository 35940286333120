import React from 'react';
import Box from '../../../components/Box';
import Logo from '../../../assets/logo.png';
import {ReactComponent as Twitter} from '../../../assets/twitter.svg';
import {ReactComponent as Instagram} from '../../../assets/instagram.svg';
import {ReactComponent as Twitch} from '../../../assets/twitch.svg';
function CustomButtons({url, icon}) {
	const Icon = icon
	return (
		<a className="flex justify-center items-center w-[60px] h-[60px] rounded-[30px] bg-primary text-black" href={url}><Icon/></a>
	)
}

function Banner({Tournament}) {
    return <Box className="h-[450px] !flex-row !p-0">
        <div className='h-full max-w-[450px] w-screen relative flex justify-center items-center profile-diagonal'>
            <div className='left-[20%] h-[calc(80%_-_20px)] aspect-square relative z-10'>
                <img className='left-[10px] top-[10px] object-contain absolute aspect-square h-[calc(100%_-_20px)]' 
                    src={Tournament?.image ?? Logo}
                />
            </div>
        </div>
        <div className='flex flex-col justify-between grow mx-[20%] my-[5%]'>
            <div>
                <div>
                    <h1 className="text-primary font-russo text-[32px] font-[400]">{Tournament?.name}</h1>
                </div>
                <br />
                <h2 className="text-white text-[24px] font-[700]">{Tournament?.information?.slot}</h2>
            </div>
            <p className='text-white text-justify'>
                {Tournament?.information?.description}
            </p>
            <div className='flex gap-[20px]'>							
                {(Tournament?.information?.twitter) && <CustomButtons icon={Twitter} url={`https://twitter.com/${Tournament.information.twitter}`}/> }
                {(Tournament?.information?.instagram) && <CustomButtons icon={Instagram} url={`https://www.instagram.com/${Tournament.information.instagram}`}/> }
                {(Tournament?.information?.twitch) && <CustomButtons icon={Twitch} url={`https://www.twitch.tv/${Tournament.information.twitch}`}/> }
            </div>
        </div>
    </Box>
}

export default Banner;