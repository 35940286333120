function NotFound() {

    return (
        <div className="main p-[20px] min-h-screen grow flex justify-center items-center">
            <h1 className="w-max font-800 text-[128px] text-text">
                404
            </h1>
        </div>
    );
}

export default NotFound;
