import { useEffect, useState } from "react";
import useFetchData from "../useFetchData";

const useTeamPlayers = (id, dependencies) => {
	const { data, error, fetchGETData } = useFetchData('team/players');
	const [users, setUsers] = useState([])

	useEffect(() => {
		if(!id) return
		fetchGETData({id})
	}, [fetchGETData, id, ...dependencies])
	
	useEffect(() => setUsers(data?.data), [setUsers, data?.data])

	return users
};

export default useTeamPlayers;