import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Banner from '../../components/Banner';
import Box from '../../components/Box';
import Button from '../../components/Button';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import TwitchChannelStream from '../../components/TwitchChannelStream';
import {ReactComponent as Twitter} from '../../assets/twitter.svg';
import {ReactComponent as Instagram} from '../../assets/instagram.svg';
import {ReactComponent as Twitch} from '../../assets/twitch.svg';
import ReactCountryFlag from "react-country-flag"
import Logo from '../../assets/logo_black.png';
import LogoWhite from '../../assets/logo.png';
import Modal from '../../components/Modal';
import Input from '../../components/Input';
import Selector from '../../components/Selector';

import useTeam from '../../api/team/useTeam';
import useUser from '../../api/user/useUser';
import useInvitations from '../../api/user/useInvitations';
import { useStoreState } from 'easy-peasy';
import useFetchData from '../../api/useFetchData';
import CountrySelector from '../../components/CountrySelector';

import badgeTournament from '../../assets/badge_tournament.png';
import badgeEvoPro from '../../assets/badge_evopro.png';
import badgeEvoChallenger from '../../assets/badge_evochallenger.png';

function CustomButtons({url, icon}) {
	const Icon = icon
	return (
		<a className="flex justify-center items-center w-[60px] h-[60px] rounded-[30px] bg-primary text-black" href={url}><Icon/></a>
	)
}

function Profile() {
	let navigate = useNavigate();
	const { t } = useTranslation();

	const userSession = useStoreState(state => state.user.data)

	const id = parseInt(useParams()?.id ?? userSession?.id);
	const { data: dataEditTeamLoad, fetchPOSTData: editProfileAPI } = useFetchData('account/edit');
	
	const {loading: data, fetchPOSTData: acceptInviteAPI} = useFetchData('account/invitations')

	if(!localStorage.getItem('token') && !id) navigate('/login')

	const [User, notFound] = useUser(id, [navigate, data, dataEditTeamLoad])

	const [Team] = useTeam(User?.team, [navigate, data, dataEditTeamLoad])

	const [showEditProfile, setShowEditProfile] = useState(false)
	const invitations = useInvitations([navigate, data])
	

	const acceptInvite = (id) => {
		acceptInviteAPI({id})
	}

	const editUser = (ev) => {
        const inputs = ev.target.parentNode.getElementsByTagName("input")
		const selectors = ev.target.parentNode.getElementsByTagName("select")
        editProfileAPI(
			{
				id, 
				name: inputs["name"].value, 
				image: inputs["image"].value,
				flag: selectors["flag"].value,
				role: selectors["role"].value,
				description: inputs["description"].value, 
				twitch: inputs["twitch"].value, 
				twitter: inputs["twitter"].value, 
				instagram: inputs["instagram"].value
			}
		)
    }

	return (
		<>
		{ !notFound && <>
		<Banner>Player Page</Banner>
		<div className='flex flex-col gap-[20px] w-[80%] mx-auto'>
			<Box className="h-[450px] !flex-row !p-0">
				<div className='h-full max-w-[450px] w-screen relative flex justify-center items-center profile-diagonal'>
					<div className='left-[20%] h-[calc(80%_-_20px)] aspect-square relative bg-primary clip-image z-10'>
						<img className='left-[10px] top-[10px] object-contain absolute aspect-square clip-image h-[calc(100%_-_20px)]' 
							src={User?.image !== "" ? User?.image : Logo}
						/>
					</div>
				</div>
				<div className='flex flex-col justify-between grow mx-[20%] my-[5%]'>
					<div>
						<div>
							<h1 className="text-primary font-russo text-[32px] font-[400]">{User?.username} <ReactCountryFlag countryCode={User?.information?.flag} /></h1>
						</div>
						<br />
						<h2 className="text-white text-[24px] font-[700]">{User?.information?.slot}</h2>
						<h2 className="text-primary font-[400]">
							Team : <Link className="underline" to={`/team/${Team?.id}`}>{Team?.name}</Link>
						</h2>
					</div>
					<p className='text-white text-justify'>
						{User?.information?.description}
					</p>
					<div className='flex gap-[20px]'>
						{User?.information?.twitter && <CustomButtons icon={Twitter} url={`https://twitter.com/${User?.information?.twitter}`}/> }
						{User?.information?.instagram && <CustomButtons icon={Instagram} url={`https://www.instagram.com/${User?.information?.instagram}`}/> }
						{User?.information?.twitch && <CustomButtons icon={Twitch} url={`https://www.twitch.tv/${User?.information?.twitch}`}/> }
					</div>
				</div>
				
			</Box>
			<div className='flex gap-[20px]'>
				<Box>

				</Box>
				<Box>
					
				</Box>
				<Box>

				</Box>
				<Box>

				</Box>
			</div>
			<div className='grid grid-cols-[2fr_1fr] gap-[20px]'>
				<div className='flex flex-col gap-[20px]'>
					{User?.information?.twitch && <Box className="!py-[0px]">
						<TwitchChannelStream channel={User?.information.twitch} />
					</Box>}
					<Box>
						{/* <CountrySelector /> */}
					</Box>
					<Box>

					</Box>
				</div>
				<div className='flex flex-col gap-[20px]'>
					<Box>
						Rank
						<div className='flex justify-evenly'>
							{User?.tournamentScore > 0 && <div>
								<img className="w-[128px] h-[128px] object-contain" src={badgeTournament} />
								Tournament: {User?.tournamentScore}RP
							</div>}
							{User?.evoproScore > 0 && <div>
								<img className="w-[128px] h-[128px] object-contain" src={badgeEvoPro} />
								Evo Pro: {User?.evoproScore}RP
							</div>}
							{User?.evochallengerScore > 0 && <div>
								<img className="w-[128px] h-[128px] object-contain" src={badgeEvoChallenger} />
								Evo Challenger: {User?.evochallengerScore}RP
							</div>}
						</div>
						
					</Box>
					<Box>

					</Box>
					
					{	id === userSession?.id &&
						<>
						<Box>
							<h1 className='font-medium text-text mx-[20px]'>{invitations?.size ?? 0} Invitations incoming</h1>
							{
							invitations?.map((i) => 
								<div key={i.id} className='mx-[20px] flex justify-between items-center'>
									<img className="w-[50px] aspect-square object-cover" src={i.team?.image !== "" ? i.team?.image : LogoWhite} alt=""/>
									<p className='text-white'>{i.team?.name}</p>
									<Button onClick={() => acceptInvite(i.id)}>Accept</Button>
								</div>
							)
							}
						</Box>
						<Button onClick={() => setShowEditProfile(true)}>
							Edit profile
						</Button>
						<Modal show={showEditProfile} title="Edit profile" onClose={() => setShowEditProfile(false)} action={editUser} actionName="Edit">
						<div className='flex flex-col gap-[10px] w-full'>
								<h1 className='font-medium text-text'>Name</h1>
								<Input name="name" type="text" defaultValue={User?.username}></Input>
							</div>
							<div className='flex flex-col gap-[10px] w-full'>
								<h1 className='font-medium text-text'>Link of the image</h1>
								<Input name="image" type="text" defaultValue={User?.image}></Input>
							</div>
							<div className='flex flex-col gap-[10px] w-full'>
								<h1 className='font-medium text-text'>Flag</h1>
								<CountrySelector name="flag" defaultValue={User?.information?.flag}/>
							</div>
							<div className='flex flex-col gap-[10px] w-full'>
								<h1 className='font-medium text-text'>Description</h1>
								<Input name="description" type="text" defaultValue={User?.information?.description}></Input>
							</div>
							<div className='flex flex-col gap-[10px] w-full'>
								<h1 className='font-medium text-text'>Role</h1>
								<Selector name="role" options={["Player", "Coach", "Manager"]} defaultValue={User?.information?.slot}></Selector>
							</div>
							<div className='flex flex-col gap-[10px] w-full'>
								<h1 className='font-medium text-text'>Twitter</h1>
								<Input name="twitter" type="text" defaultValue={User?.information?.twitter}></Input>
							</div>
							<div className='flex flex-col gap-[10px] w-full'>
								<h1 className='font-medium text-text'>Instagram</h1>
								<Input name="instagram" type="text" defaultValue={User?.information?.instagram}></Input>
							</div>
							<div className='flex flex-col gap-[10px] w-full'>
								<h1 className='font-medium text-text'>Twitch</h1>
								<Input name="twitch" type="text" defaultValue={User?.information?.twitch}></Input>
							</div>
						</Modal>
						<Button red to="/logout">
							Log Out
						</Button>
					</>
					}
					
				</div>
			</div>
		</div>
		</>}
		</>
	);
}

export default Profile;
