import { useState, useCallback } from 'react';

const useFetchData = (endpoint) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const fetchGETData = useCallback((args) => {
        const headers = new Headers();
        headers.append('Authorization', localStorage.getItem("token"))

        let options = {
            headers
        };

        setLoading(true)
        fetch(`https://api.apexpro.web.projectheberg.com/${endpoint}?${new URLSearchParams(args)}`, options)
            .then(response => response.json())
            .then(data => {
                if (data.error) throw data
                setData(data);
                setError(false);
            })
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [endpoint]);

    const fetchPOSTData = useCallback((body) => {
        const headers = new Headers();
        headers.append('Authorization', localStorage.getItem("token"))

        let options = {
            headers
        };
        options.method = "POST";
        options.body = JSON.stringify(body);

        setLoading(true)
        fetch(`https://api.apexpro.web.projectheberg.com/${endpoint}`, options)
            .then(response => response.json())
            .then(data => {
                setData(data);
                if (data.error) throw data
            })
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [endpoint]);

    const fetchDELETEData = useCallback((body) => {
        const headers = new Headers();
        headers.append('Authorization', localStorage.getItem("token"))

        let options = {
            headers
        };
        options.method = "DELETE";
        options.body = JSON.stringify(body);

        setLoading(true)
        fetch(`https://api.apexpro.web.projectheberg.com/${endpoint}`, options)
            .then(response => response.json())
            .then(data => {
                setData(data);
                if (data.error) throw data
            })
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [endpoint]);

    const fetchPATCHData = useCallback((body) => {
        const headers = new Headers();
        headers.append('Authorization', localStorage.getItem("token"))

        let options = {
            headers
        };
        options.method = "PATCH";
        options.body = JSON.stringify(body);

        setLoading(true)
        fetch(`https://api.apexpro.web.projectheberg.com/${endpoint}`, options)
            .then(response => response.json())
            .then(data => {
                setData(data);
                if (data.error) throw data
            })
            .catch(e => setError(e))
            .finally(() => setLoading(false))
    }, [endpoint]);

    return { data, overrideData: setData, error, loading, fetchGETData, fetchPOSTData, fetchDELETEData, fetchPATCHData };
};

export default useFetchData;