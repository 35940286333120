import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Banner from '../../components/Banner';
import Box from '../../components/Box';
import Button from '../../components/Button';
import {ReactComponent as IconPlayer} from '../../assets/player.svg';
import Logo from '../../assets/logo.png';
import { Link, useParams } from 'react-router-dom';
import TwitchChannelStream from '../../components/TwitchChannelStream';
import Scoring from '../../components/Scoring';
import Modal from '../../components/Modal';
import BannerTournament from './parts/banner';

import useTournament from '../../api/tournament/useTournament';
import useTournamentTeams from '../../api/tournament/useTournamentTeams';
import useUser from '../../api/user/useUser';
import { useStoreState } from 'easy-peasy';
import useTeam from '../../api/team/useTeam';
import useFetchData from '../../api/useFetchData';
import useTournamentMatches from '../../api/tournament/useTournamentMatches';
import useTournamentLeaderboard from '../../api/tournament/useTournamentLeaderboard';
import useTournamentStats from '../../api/tournament/useTournamentStats';

function Tournament() {
	let navigate = useNavigate();
	const { t } = useTranslation();

	const id = parseInt(useParams()?.id);
	const round = parseInt(useParams()?.round);

	const userSession = useStoreState(state => state.user.data);
	const connected = localStorage.getItem('token') ? true : false
	const [userTeam] = useTeam(userSession?.team, [navigate, round])

	const [showJoinTournament, setShowJoinTournament] = useState(false)
	const {fetchPOSTData, loading: joinLoading} = useFetchData('tournament/join');

	const [Tournament] = useTournament(id, [navigate])

	if(round > ((Tournament?.information?.round ?? 0)+1)) navigate("/tournament/"+id)

	const [TournamentTeams] = useTournamentTeams(id, [navigate, round, showJoinTournament, joinLoading])
	const [GroupsMatches] = useTournamentMatches(id, (round ? round - 1 : Tournament?.information?.round), [navigate, round, showJoinTournament, joinLoading, Tournament])
	const [leaderboard] =  useTournamentLeaderboard(id, [navigate, round, showJoinTournament, joinLoading, Tournament])
	const [stats] =  useTournamentStats(id, [navigate, round, showJoinTournament, joinLoading, Tournament])

	console.log(stats)

	const [Author, AuthorNotFound] = useUser(Tournament?.owner, [navigate])

	const joinTournament = () => {
		fetchPOSTData({"team_id": userSession?.team, "tournament_id": id})
	}

	const LeaderboardTeams = TournamentTeams?.sort((a, b) => {
		if(!leaderboard) return;
		return (leaderboard[b.id] ?? 0) - (leaderboard[a.id] ?? 0)
	})

	return (
		<>
		<Banner>Tournament Page</Banner>
		<div className='flex flex-col gap-[20px] w-[80%] mx-auto'>
			<BannerTournament Tournament={Tournament}/>
			<div className='flex gap-[20px]'>
				<Box className="flex justify-center items-center !flex-row">
					{(round ? round - 1 : Tournament?.information?.round) > 0 && <Link to={`/tournament/${id}/round/${(round ? round - 1 : Tournament?.information?.round)}`}>-</Link>}
					<h1 className='font-normal text-white text-[1.5em] font-russo'>Round {(round ? round - 1 : Tournament?.information?.round) + 1}</h1>
					{(round ? round - 1 : Tournament?.information?.round) < Tournament?.information?.round && <Link to={`/tournament/${id}/round/${(round ? round - 1 : Tournament?.information?.round) + 2}`}>+</Link>}
				</Box>
				<Box className="flex justify-center items-center flex-col">
					<h1 className='font-normal text-white text-[1.5em] font-russo'>Tournament by</h1>
					{
						!AuthorNotFound ?
						<Link to={`/profile/${Author?.id}`} className="text-primary underline">{Author?.username}</Link> :
						<h1 className='font-normal text-white text-[1.5em] font-russo'>author not found</h1>
					}
				</Box>
				<Box className="flex justify-center items-center flex-col">
					<h1 className='font-normal text-white text-[1.5em] font-russo'>{Tournament?.cashprize?.total}$</h1>
				</Box>
				<Box className="flex justify-center items-center flex-col">

				</Box>
			</div>
			<div className='grid grid-cols-[2fr_1fr] gap-[20px]'>
				<div className='flex flex-col gap-[20px] max-w-1/3 overflow-hidden'>
					{(Tournament?.closed == 1) && <Box>
						<h1 className='font-normal text-white text-[1.5em] font-russo'>This tournament is ended</h1>
					</Box>}
					{(Tournament?.closed == 0) && <Box className="!py-[0px]">
						<TwitchChannelStream channel={Tournament?.information?.twitch} />
					</Box>}
					<Box>
						{
						GroupsMatches?.map((g, i) => 
						<details className='odd:bg-secondary'>
							<summary className='font-normal text-white text-[1.5em] font-russo'>Group {i+1}</summary>
							{Object.keys(g?.score)?.sort((a, b) => -g?.score[a] + g?.score[b])?.map(team => {
								const t = TournamentTeams?.find(t => t.id == team)
								return <div>
									<p>{t?.team.name} - {g.score[team] ?? 0} point{(g.score[team] ?? 0) > 1 && 's'}</p>
								</div>
							})}
							{g?.matches?.map((m, i) => 
								<details>
									<summary className='odd:bg-background font-normal text-white text-[1.5em] font-russo'>Game {i+1}</summary>
									{m?.teams_in_game.sort((a, b) => -m.score[a.id] + m.score[b.id]).map(t => {
									let players = m?.match?.player_results?.filter(p => p.teamNum === t.number)

									return <div className='odd:bg-background'>
									<h1>{t.team.name} - {m.score[t.id] ?? 0} points</h1>
									{
										players?.map((p) => <div>
											{p.playerName}
										</div>)
									}</div>
									})}
								</details>
							)}
						</details>
						)
						}
					</Box>
				</div>
				<div className='flex flex-col gap-[20px]'>
					<Scoring />
					<Box className="flex gap-[20px]">
						<h1 className='flex items-center gap-[10px] justify-center font-normal text-white text-[32px] font-russo'><IconPlayer className='w-[24px] h-[24px] text-primary'/>Teams registered</h1>
						{
							TournamentTeams?.map((tournamentTeam) => 
								<div key={tournamentTeam?.id} className='mx-[20px]'>
									<Link className="flex gap-[20px] items-center" to={"/team/"+tournamentTeam.team?.id}>
										<img className="w-[50px] aspect-square object-cover" src={tournamentTeam.team?.image !== "" ? tournamentTeam.team?.image : Logo} alt=""/>
										<div>
											<p className='text-white'>{tournamentTeam.team?.name}</p>
											<p>Storm Point: {tournamentTeam.spawnStorm || 'Not set'}</p>
											<p>World Edge: {tournamentTeam.spawnWorld || 'Not set'}</p>
										</div>
										
									</Link>
								</div>
							)
						}
					</Box>
					<Box>
						<h1 className='font-normal text-white text-[1.5em] font-russo'>Top performance leaderboard</h1>
						{
							LeaderboardTeams?.map((t, i) => <div className='flex gap-[20px] items-center odd:bg-background'>
								<h1 className='w-[50px] h-[50px] bg-primary flex items-center justify-center text-black font-normal text-white text-[2.5em] font-russo'>
									{i+1}
								</h1>
								{t.team.name} - {leaderboard && <>{leaderboard[t.id] ?? 0} point{(leaderboard[t.id] ?? 0) > 1 && 's'}</>}
							</div>)
						}
					</Box>
					{
						(connected && ((TournamentTeams?.find(t => t.team.owner === userSession?.id)) || Tournament?.players?.find(u => u === userSession?.id))) && <>
							<Button to={`/tournament/${id}/play`}>
								Access player side
							</Button>
						</>
					}
					{
						connected && ((userSession?.id === userTeam?.owner) && (!TournamentTeams?.find(t => t.team.id === userTeam?.id))) && <>
							<Button onClick={() => setShowJoinTournament(true)}>
								Join tournament
							</Button>
							<Modal show={showJoinTournament} title="Join tournament" onClose={() => setShowJoinTournament(false)} action={joinTournament} actionName="Join">
								Are you sure to sign your team "{userTeam?.name}" to this tournament ?
							</Modal>
						</>
					}
					{ userSession?.id === Author?.id && <Button to={`/tournament/${id}/manage`}>
						Manage tournament
					</Button>}
				</div>
			</div>
		</div>
		
		</>
	);
}

export default Tournament;
