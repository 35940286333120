import { useEffect, useState } from "react";
import useFetchData from "../useFetchData";

const useUser = (id, dependencies) => {
	const { data, error, fetchGETData } = useFetchData('user');
	const [user, setUser] = useState({})

	useEffect(() => {
		if(!id) return
		fetchGETData({id})
	}, [fetchGETData, id, ...dependencies])
	
	useEffect(() => setUser(data?.data), [setUser, data?.data])

	return [user, error?.code === 404]
};

export default useUser;