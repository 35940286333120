import React, { useState } from 'react';
import {Link as Lnk, useLocation} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

function Header() {

    const { t } = useTranslation();
    const location = useLocation();

    const [open, setOpen] = useState(false);

    const link = [
        {
            name: t('header.link.tournaments'),
            url: "/tournaments/"
        },
        {
            name: t('header.link.evo'),
            url: "/evo/"
        },
        {
            name: t('header.link.ranking'),
            url: "/ranking/"
        },
        {
            name: t('header.link.dropmap'),
            url: "/dropmap/"
        },
        {
            name: t('header.link.teams'),
            url: "/teams/"
        }
    ]

    let [connected, setConnected] = useState(localStorage.getItem('token'));

    useEffect(() => {
        setConnected(localStorage.getItem('token'))
    }, [location])


    return (
        <>
        {location.pathname === "/" ?
        <header className='flex justify-center text-text z-30 w-full absolute top-[6rem]'>
            <div className='w-[60%] flex justify-center gap-[40px] items-center'>
                <div className='w-full flex justify-center gap-[40px] items-center'>
                    {
                        link.slice(0, 3).map((l) => <Lnk className="uppercase" to={l.url} key={l.url}>{l.name}</Lnk>)
                    }
                </div>
                <Lnk to="/" className='w-[70px] w-min-[70px]'>
                    <img src={require("../assets/logo.png")} alt="logo" className='w-[70px] w-min-[70px]'/>
                </Lnk>
                <div className='w-full flex justify-center gap-[40px] items-center'>
                    {
                        link.slice(3).map((l) => <Lnk className="uppercase" key={l.url} to={l.url}>{l.name}</Lnk>)
                    }
                    { !connected
                        ?
                        <>
                        <Lnk className="uppercase" to='/login/'>{t('header.profile.logsign')}</Lnk>
                        </>
                        :
                        <>
                        <Lnk className="uppercase" to='/profile/'>{t('header.profile.profile')}</Lnk>
                        </>
                    }
                </div>
            </div>
        </header>
        :
        <header className='h-[80px] shadow-header bg-background-light flex justify-center text-text z-30 w-full sticky top-[0px]'>
            <div className='w-[60%] flex justify-center gap-[40px] items-center'>
                <div className='w-full flex justify-center gap-[40px] items-center'>
                    {
                        link.slice(0, 3).map((l) => <Lnk className="uppercase" to={l.url} key={l.url}>{l.name}</Lnk>)
                    }
                </div>
                <Lnk to="/" className='w-[70px] w-min-[70px]'>
                    <img src={require("../assets/logo.png")} alt="logo" className='w-[70px] w-min-[70px]'/>
                </Lnk>
                <div className='w-full flex justify-center gap-[40px] items-center'>
                    {
                        link.slice(3).map((l) => <Lnk className="uppercase" key={l.url} to={l.url}>{l.name}</Lnk>)
                    }
                    { !connected
                        ?
                        <>
                        <Lnk className="uppercase" to='/login/'>{t('header.profile.logsign')}</Lnk>
                        </>
                        :
                        <>
                        <Lnk className="uppercase" to='/profile/'>{t('header.profile.profile')}</Lnk>
                        </>
                    }
                </div>
            </div>
        </header>
        }
        
            <div className={(open ? "translate-y-[100px]" : "-translate-y-full") +' transition-transform duration-[500ms] z-20 flex flex-col bg-primary-normal fixed w-full'}>
                {link.map(link => <Lnk onClick={() => setOpen(false)} className="hover:bg-primary-dark h-fit text-white no-underline px-[10px] py-[15px]" key={link.url} to={link.url}>{link.name}</Lnk>)}
                <hr className='border-primary-light'/>
                { !connected
                ?
                <>
                <Lnk onClick={() => setOpen(false)} className="hover:bg-primary-dark h-fit text-white no-underline px-[10px] py-[15px]" to='/login'>{t('header.profile.login')}</Lnk>
                <Lnk onClick={() => setOpen(false)} className="hover:bg-primary-dark h-fit text-white no-underline px-[10px] py-[15px]" to='/signin'>{t('header.profile.signin')}</Lnk>
                </>
                :
                <>
                <Lnk onClick={() => setOpen(false)} className="hover:bg-primary-dark h-fit text-white no-underline px-[10px] py-[15px]" to='/profile'>{t('header.profile.profile')}</Lnk>
                </>
                }
            </div>
        </>
    );
}

export default Header;