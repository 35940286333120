import { useEffect } from 'react';
import useFetchData from '../api/useFetchData'
import Loader from '../components/Spinner';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Input from '../components/Input';
import Link from '../components/Link';
import Button from '../components/Button';
import Banner from '../components/Banner';

function Login() {
    const navigate = useNavigate()

    const { t } = useTranslation();
    const {data, error, loading, fetchPOSTData} = useFetchData('account/auth');
    
    const useForm = (event) => {
        event.preventDefault();
        
        var {uname, pass} = document.forms[0];

        fetchPOSTData({username: uname.value, password: pass.value})
    }

    useEffect(() => {
			if(data?.code === 200){
				localStorage.setItem('token', data.token);
			navigate("/profile/");
        }
    }, [data,navigate])

    return (
        <>
        <Banner>
            Login
        </Banner>
            <form onSubmit={useForm}  className='bg-background-light flex flex-col w-[50%] mx-auto my-0 p-[20px] rounded-[5px] items-center gap-[20px] mb-[40px]'>
                <h1 className='font-[400] font-russo text-[32px] text-white'>{t('auth.login.title')}</h1>
                <h3 className='font-[700] text-[1.17em] text-white'>{t('auth.login.subtitle')}</h3>
                <div className='flex flex-col gap-[10px] w-full'>
                    <h1 className='font-medium text-text'>{t('form.username')}</h1>
                    <Input name="uname"></Input>
                </div>

                <div className='flex flex-col gap-[10px] w-full'>
                    <h1 className='font-medium text-text'>{t('form.password')}</h1>
                    <Input name="pass" type="password"></Input>
                </div>
                
                <Button type="full" onClick={useForm} className="w-full">Login to your account</Button>
                <Link to="/signin">Sign in</Link>
                {loading && <Loader/>}
            </form>
        </>
    )
}

export default Login;
