import { useStoreActions } from 'easy-peasy';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Logout(props) {
    let navigate = useNavigate();

    const clearData = useStoreActions(action => action.user.clear)

    useEffect(() => {
        clearData();
        navigate('/');
    }, [navigate, clearData])
}

export default Logout;