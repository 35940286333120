import React from 'react';
import { useNavigate } from 'react-router-dom';
import useTournamentGroups from '../../../api/tournament/useTournamentGroups';
import useFetchData from '../../../api/useFetchData';
import Box from '../../../components/Box';
import Button from '../../../components/Button';
import Input from '../../../components/Input';

function Groups({tournament, reloadArray, forceReload}) {

    const navigate = useNavigate();

    const {loading: loadingAddGroups, fetchPOSTData} = useFetchData('tournament/groups')
    const {loading: loadingDeleteGroups, fetchDELETEData} = useFetchData('tournament/groups')
    const {loading: loadingEditGroup, fetchPATCHData} = useFetchData('tournament/groups')
    const {loading: loadingMatchGroups, fetchPATCHData: collectMatchGroup} = useFetchData('tournament/groups/match')
    
	const { data: checkLeaveTeam, fetchPOSTData: leaveTeamAPI } = useFetchData('tournament/groups/leave');

    const [Groups] = useTournamentGroups(tournament, [navigate, loadingAddGroups, loadingDeleteGroups, loadingEditGroup, loadingMatchGroups, ...reloadArray, checkLeaveTeam])

    const EditGroup = (event, g) => {
        const GroupDOM = event.target.parentNode
        const joinKeyDOM = GroupDOM.getElementsByClassName('joinKey')[0]
        const apiTokenDOM = GroupDOM.getElementsByClassName('apiToken')[0]

        fetchPATCHData({joinKey: joinKeyDOM.value || g.joinKey, APIKey: apiTokenDOM.value || g.apiToken, id: g.id})

        joinKeyDOM.value = ""
        apiTokenDOM.value = ""
    }

    const CollectMatch = (id) => {
        collectMatchGroup({id})
    }

    const removeTeamGroup = (team) => {
		leaveTeamAPI({
			tournament_id: tournament,
			team_id: team
		})
        forceReload(Date.now())
	}

    return (
        <>
        <h1 className='font-normal text-white text-[32px] font-russo'>
            Groups
        </h1>
        <div>
            {
                Groups?.map((g, i) => 
                <Box>
                    <p>Group {i+1}</p>
                    <div className="flex">
                        <div className='w-1/2'>
                            <Input placeholder={g?.joinKey || 'Join Key'} className="joinKey"></Input>
                            <Input placeholder={g?.apiToken || 'API Key'} className="apiToken"></Input>
                            <details><summary>Code</summary><pre>{JSON.stringify(JSON.parse(g?.matchJSON), null, 2)}</pre></details>
                            <Button onClick={(ev) => EditGroup(ev, g)}>Edit</Button>
                            <Button onClick={() => CollectMatch(g.id)}>Collect Match</Button>
                            <Button red onClick={() => {fetchDELETEData({id: g.id})}}>Remove</Button>
                        </div>
                        <div className='w-1/2 max-w-[50%] overflow-hidden'>
                            {g?.teams?.map(t => 
                                <div className='flex items-center justify-between'>
                                    <div>
                                        <p>{t.number} - {t.team.name}</p>
                                    </div>
                                    <Button onClick={() => removeTeamGroup(t.id)}>remove</Button>
                                </div>
                            )}
                        </div>
                    </div>
                    
                </Box>)
            }
        </div>
        <Button onClick={() => fetchPOSTData({'tournament_id': tournament})}>Add group</Button>
        </>
    );
}

export default Groups;