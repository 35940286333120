import { useEffect, useState } from "react";
import useFetchData from "../useFetchData";

const useUser = (dependencies) => {
	const { data, error, fetchGETData } = useFetchData('account/invitations');
	const [invitations, setInvitations] = useState([])

	useEffect(() => {
		fetchGETData()
	}, [fetchGETData, ...dependencies])
	
	useEffect(() => setInvitations(data?.data), [setInvitations, data?.data])

	return invitations
};

export default useUser;