import { useEffect, useState } from 'react';
import useFetchData from '../../api/useFetchData';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Banner from '../../components/Banner';
import { Link, useParams } from 'react-router-dom';
import {ReactComponent as Twitter} from '../../assets/twitter.svg';
import {ReactComponent as Instagram} from '../../assets/instagram.svg';
import {ReactComponent as Twitch} from '../../assets/twitch.svg';
import Logo from '../../assets/logo.png'
import { useStoreState } from 'easy-peasy';

import useTeam from '../../api/team/useTeam';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Input from '../../components/Input';

function CustomButtons({url, icon}) {
	const Icon = icon
	return (
		<a className="flex justify-center items-center w-[30px] h-[30px] rounded-[15px] bg-primary" href={url}><Icon/></a>
	)
}

function Offers() {
	let navigate = useNavigate();
	const { t } = useTranslation();

	const id = parseInt(useParams().id);

	const { data, error, fetchGETData } = useFetchData('team');
    const { data: dataCreateTeamLoad, fetchPOSTData: createTeamAPI } = useFetchData('team/create');

    const userSession = useStoreState(state => state.user.data);
	const [userTeam, notFoundUserTeam] = useTeam(userSession?.team, [navigate, dataCreateTeamLoad])

    const [showCreateTeam, setShowCreateTeam] = useState(false)

	useEffect(() => {
		fetchGETData()
	}, [fetchGETData, navigate, id, dataCreateTeamLoad])

    const connected = localStorage.getItem('token') ? true : false

    const createTeam = (ev) => {
        const inputs = ev.target.parentNode.getElementsByTagName("input")
        createTeamAPI({name: inputs["name"].value})
        const user = JSON.parse(localStorage.getItem('user'))
        user.team = dataCreateTeamLoad?.data?.id
        localStorage.setItem('user', JSON.stringify(user))
    }

	return (
        <>
        <Banner>Teams</Banner>
        <div className='flex flex-col w-4/5 mx-auto gap-[20px]'>
            
        {
                userTeam && <>
                <h1 className="text-white font-russo text-[32px] font-[400]">Your team</h1>
                <div className='divide-y divide-secondary border-secondary border-[1px]'>
                    <div className='gap-[20px] h-[100px] mx-auto flex items-center h-full px-[10%] gap-[20px] odd:bg-background-light even:bg-background'>
                        <img src={userTeam?.image !== "" ? userTeam?.image : Logo} className='h-[80px] w-[160px] object-contain'/>
                        <Link to={"/team/"+userTeam.id}><h1 className='text-white font-normal font-russo text-[1.5rem]'>{userTeam.name}</h1></Link>
                        <div className='flex gap-[10px]'>
                            {userTeam.information?.twitter && <CustomButtons icon={Twitter} url={`https://twitter.com/${userTeam.information?.twitter}`}/> }
                            {userTeam.information?.instagram && <CustomButtons icon={Instagram} url={`https://www.instagram.com/${userTeam.information?.instagram}`}/> }
                            {userTeam.information?.twitch && <CustomButtons icon={Twitch} url={`https://www.twitch.tv/${userTeam.information?.twitch}`}/> }
                        </div>
                    </div>
                </div>
                </>
            }
            {
                connected && !userSession?.team && <>
                    <Button onClick={() => setShowCreateTeam(true)}>Create team</Button>
                    <Modal show={showCreateTeam} title="Create team" onClose={() => setShowCreateTeam(false)} action={createTeam} actionName="Create">
                        <div className='flex flex-col gap-[10px] w-full'>
                            <h1 className='font-medium text-text'>Name of the team</h1>
                            <Input name="name" type="text"></Input>
                        </div>
					</Modal>
                </>
                
            }
            {userSession?.team && <h1 className="text-white font-russo text-[32px] font-[400]">Others teams</h1> }
            <div className='divide-y divide-secondary border-secondary border-[1px]'>
            {
                data && data.data.map((team) => 
                    <div className='gap-[20px] h-[100px] mx-auto flex items-center h-full px-[10%] gap-[20px] odd:bg-background-light even:bg-background'>
                        <img src={team?.image !== "" ? team?.image : Logo} className='h-[80px] w-[160px] object-contain'/>
                        <Link to={"/team/"+team.id}><h1 className='text-white font-normal font-russo text-[1.5rem]'>{team.name}</h1></Link>
                        <div className='flex gap-[10px]'>
                            {team.information?.twitter && <CustomButtons icon={Twitter} url={`https://twitter.com/${team.information?.twitter}`}/> }
                            {team.information?.instagram && <CustomButtons icon={Instagram} url={`https://www.instagram.com/${team.information?.instagram}`}/> }
                            {team.information?.twitch && <CustomButtons icon={Twitch} url={`https://www.twitch.tv/${team.information?.twitch}`}/> }
                        </div>
                    </div>
                )
            }
            </div>
        </div>
        </>
    )
}

export default Offers;
