import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Banner from '../../components/Banner';
import { Link, useParams } from 'react-router-dom';
import {ReactComponent as Twitter} from '../../assets/twitter.svg';
import {ReactComponent as Instagram} from '../../assets/instagram.svg';
import {ReactComponent as Twitch} from '../../assets/twitch.svg';
import Logo from '../../assets/logo.png';

import useTournamentList from '../../api/tournament/useTournamentList';
import { useState } from 'react';
import { useStoreState } from 'easy-peasy';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Input from '../../components/Input';
import useFetchData from '../../api/useFetchData';

function CustomButtons({url, icon}) {
	const Icon = icon
	return (
		<a className="flex justify-center items-center w-[30px] h-[30px] rounded-[15px] bg-primary" href={url}><Icon/></a>
	)
}

function List() {
	let navigate = useNavigate();

	const { t } = useTranslation();

	
    const userSession = useStoreState(state => state.user.data)
    const [showCreateTournament, setShowCreateTournament] = useState(false)
    const { data: dataCreateTournamentLoad, fetchPOSTData: createTournamentAPI } = useFetchData('tournament/create');
    const Tournaments = useTournamentList([navigate, dataCreateTournamentLoad])

    const createTournament = (ev) => {
        const inputs = ev.target.parentNode.getElementsByTagName("input")
        createTournamentAPI({name: inputs["name"].value, cashprize: inputs["cashprize"].value})
    }

	return (
        <>
        <Banner>tournaments</Banner>
        <div className='flex flex-col w-4/5 mx-auto'>
            {
                userSession?.admin && <>
                    <Button onClick={() => setShowCreateTournament(true)}>Create tournament</Button>
                    <Modal show={showCreateTournament} title="Create team" onClose={() => setShowCreateTournament(false)} action={createTournament} actionName="Create">
                        <div className='flex flex-col gap-[10px] w-full'>
                            <h1 className='font-medium text-text'>Name of the tournament</h1>
                            <Input name="name" type="text"></Input>
                        </div>
                        <div className='flex flex-col gap-[10px] w-full'>
                            <h1 className='font-medium text-text'>cashprize total</h1>
                            <Input name="cashprize" type="text"></Input>
                        </div>
					</Modal>
                </>
                
            }
            <div className='divide-y divide-secondary border-secondary border-[1px]'>
            
            {
                Tournaments?.map((tournament) => 
                    <div key={tournament.id} className='gap-[20px] h-[100px] mx-auto flex items-center h-full px-[10%] gap-[20px] odd:bg-background-light even:bg-background'>
                        <img src={tournament?.image ?? Logo} className='h-[80px] w-[160px] object-contain'/>
                        <Link to={"/tournament/"+tournament.id}><h1 className='text-white font-normal font-russo text-[1.5rem]'>{tournament.name}</h1></Link>
                        <h1 className='text-white font-normal font-russo text-[1.5rem]'>{tournament.cashprize?.total}$</h1>
                    </div>
                )
            }
            </div>
        </div>
        </>
    )
}

export default List;
