import React from 'react'

function TwitchChannelStream(props) {
  return (
    <iframe src={`https://embed.twitch.tv?channel=${props.channel ?? 'g-fuzion'}&height=480&parent=gfuzion.web.projectheberg.com`} allowFullScreen="" scrolling="no" frameBorder="0" allow="autoplay; fullscreen" title="Twitch" sandbox="allow-modals allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox" width="100%" height="480">

    </iframe>
  )
}

export default TwitchChannelStream