import React from 'react';

function Banner(props) {
    return (
        <div className='bg-primary flex items-center justify-between px-[10%] h-[140px] uppercase mb-[60px]'>
                <h1 className='font-[400] text-[32px] font-russo'>{props.children}</h1>
        </div>
    );
}

export default Banner;