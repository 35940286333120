import { useTranslation } from 'react-i18next';
import useFetchData from '../api/useFetchData';
import Button from '../components/Button';

function Homepage() {

    const {data, error, loading, fetchPOSTData} = useFetchData('password')
    const { t } = useTranslation();

    return (
        <>
            <div className='main w-full h-screen'>
                <Button onClick={() => fetchPOSTData({password: 12345})}>
                    Play
                </Button>
                <Button onClick={() => fetchPOSTData({password: 12345})}>
                    Ranking
                </Button>
            </div>
        </>
    );
}

export default Homepage;
