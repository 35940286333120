import React from 'react';
import { useTranslation } from 'react-i18next';
import {ReactComponent as Twitter} from '../assets/twitter.svg';
import {ReactComponent as Twitch} from '../assets/twitch.svg';
import { Link as Lnk } from 'react-router-dom';

function Footer(props) {

    const { t } = useTranslation();

    const usefulLink = [
        {
            name: t('footer.usefullink.home'),
            url: "/"
        },
        {
            name: t('footer.usefullink.news'),
            url: "/news/"
        },
        {
            name: t('footer.usefullink.posts'),
            url: "/posts/"
        },
        {
            name: t('footer.usefullink.teams'),
            url: "/teams/"
        },
        {
            name: t('footer.usefullink.teamstats'),
            url: "/teamstat/"
        },
        {
            name: t('footer.usefullink.roster'),
            url: "/roster/"
        },
        {
            name: t('footer.usefullink.tournaments'),
            url: "/tournaments/"
        }
    ]

    const aboutLink = [
        {
            name: t('footer.about.contact'),
            url: "/tournaments/"
        },
        {
            name: t('footer.about.sponsors'),
            url: "/scrims/"
        },
        {
            name: t('footer.about.nextevents'),
            url: "/nextevent/"
        },
        {
            name: t('footer.about.guides'),
            url: "/guides/"
        },
        {
            name: t('footer.about.auth'),
            url: "/login/"
        },
        {
            name: t('footer.about.privacypolicy'),
            url: "/privacy/"
        }
    ]

    return (
        <footer className='border-t-2 border-primary bg-background'>
            <div className='flex justify-evenly py-[60px]'>
                <div>
                    <h4 className='uppercase text-text mb-[40px]'>{t('footer.usefullinks')}</h4>
                    <ul className='flex flex-col gap-[10px]'>
                        {
                            usefulLink.map((l) => <Lnk className="before:content-['>'] before:w-[1em] before:ml-[-1em] before:text-primary before:inline-block relative text-text-trademarks hover:text-primary transition" to={l.url} key={l.url}>{l.name}</Lnk>)
                        }
                    </ul>
                </div>
                <div>
                    <h4 className='uppercase text-text mb-[40px]'>{t('footer.streams')}</h4>
                    <ul className='flex flex-col gap-[10px]'>
                        {/* {
                            usefulLink.map((l) => <Lnk  className="before:content-['>'] before:w-[1em] before:ml-[-1em] before:text-primary before:inline-block relative text-text-trademarks hover:text-primary transition" to={l.url} key={l.url}>{l.name}</Lnk>)
                        } */}
                    </ul>
                </div>
                <div>
                    <h4 className='uppercase text-text mb-[40px]'>{t('footer.aboutus')}</h4>
                    <ul className='flex flex-col gap-[10px]'>
                        {
                            aboutLink.map((l) => <Lnk  className="before:content-['>'] before:w-[1em] before:ml-[-1em] before:text-primary before:inline-block relative text-text-trademarks hover:text-primary transition" to={l.url} key={l.url}>{l.name}</Lnk>)
                        }
                    </ul>
                </div>
                <div>
                    <h4 className='uppercase text-text mb-[40px]'>{t('footer.latesttournament')}</h4>
                </div>
            </div>
            <div className='bg-background-light h-[80px] flex justify-evenly items-center'>
                <div className='flex gap-[10px] items-center'>
                    <Twitter className="text-primary w-[32px] h-[32px]"/>
                    <div>
                        <h3 className='text-text font-[400] font-russo uppercase text-[14px]'>Twitter</h3>
                        <h3 className='italic font-[600] text-text-light text-[14px]'>@playGfuzion</h3>
                    </div>
                </div>
                <div className='flex gap-[10px] items-center'>
                    <Twitch className="text-primary w-[32px] h-[32px]"/>
                    <div>
                        <h3 className='text-text font-[400] font-russo uppercase text-[14px]'>Twitch</h3>
                        <h3 className='italic font-[600] text-text-light text-[14px]'>@playGfuzion</h3>
                    </div>
                </div>
            </div>
            <div>
                <p className='text-text-trademarks text-[9px] m-auto w-[80%] h-[80px] flex justify-evenly items-center'>{t('footer.trademarks')}</p>
            </div>
        </footer>
    );
}

export default Footer;