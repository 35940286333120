import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import Banner from '../../components/Banner';
import Box from '../../components/Box';
import Button from '../../components/Button';
import {ReactComponent as IconPlayer} from '../../assets/player.svg';
import Logo from '../../assets/logo.png';
import { Link, useParams } from 'react-router-dom';
import TwitchChannelStream from '../../components/TwitchChannelStream';
import Scoring from '../../components/Scoring';
import useFetchData from '../../api/useFetchData';
import Modal from '../../components/Modal';
import { useState } from 'react';
import Input from '../../components/Input';

import useTournament from '../../api/tournament/useTournament';
import useTournamentTeams from '../../api/tournament/useTournamentTeams';
import useTournamentGroups from '../../api/tournament/useTournamentGroups';
import useUser from '../../api/user/useUser';
import { useEffect } from 'react';

import Groups from './parts/groups';
import Selector from '../../components/Selector';

function Manage() {
	let navigate = useNavigate();
	const { t } = useTranslation();

	const id = parseInt(useParams()?.id);
	const userSession = useStoreState(state => state.user.data);
	const { data: dataEditTournamentLoad, fetchPOSTData: editTournamentAPI } = useFetchData('tournament/edit');
	const { data: dataRoundLoad, fetchGETData: updateRoundAPI } = useFetchData('tournament/round');
	const { data: checkLoad, fetchGETData: checkinTournamentAPI } = useFetchData('tournament/checkin');
	const { data: checkJoinTeam, fetchPOSTData: joinTeamAPI } = useFetchData('tournament/groups/join');

	const [forceReload, reload] = useState(false);

	const [Tournament] = useTournament(id, [navigate, dataEditTournamentLoad,  dataRoundLoad, checkLoad, checkJoinTeam, forceReload])
	const [TournamentTeams] = useTournamentTeams(id, [navigate, dataEditTournamentLoad,  dataRoundLoad, checkLoad, checkJoinTeam, forceReload])
	const [GroupsData] = useTournamentGroups(id, [navigate, dataEditTournamentLoad,  dataRoundLoad, checkLoad, checkJoinTeam, forceReload])

	const [Author, AuthorNotFound] = useUser(Tournament?.owner, [navigate, dataEditTournamentLoad, checkLoad, forceReload])

	useEffect(() => {
		if(!Author || Object.keys(Author).length === 0) return
		if(userSession?.id !== Author?.id) navigate(`/tournament/${id}`)
	}, [userSession, Author])

	const { fetchGETData: deleteTournament } = useFetchData('tournament/delete');
	const { fetchGETData: finishTournamentAPI } = useFetchData('tournament/finish');
	
	const [showDeleteTournament, setShowDeleteTournament] = useState(false)
	const [showEditTournament, setShowEditTournament] = useState(false)

	const editTournament = (ev) => {
        const inputs = ev.target.parentNode.getElementsByTagName("input")
		const selectors = ev.target.parentNode.getElementsByTagName("select")
        editTournamentAPI(
			{
				id, 
				name: inputs["name"].value, 
				image: inputs["image"].value,
				description: inputs["description"].value, 
				twitch: inputs["twitch"].value, 
				twitter: inputs["twitter"].value, 
				instagram: inputs["instagram"].value
			}
		)
    }

	const toggleCheckin = () => {
		checkinTournamentAPI({id})
	}

	const setTeamGroup = (team, ev) => {
		let selectChoice = ev.target.options[ev.target.selectedIndex].value
		joinTeamAPI({
			tournament_id: id,
			group_id: GroupsData[selectChoice]?.id,
			team_id: team
		})
	}

	const nextRound = () => {
		updateRoundAPI({id})
	}

	const finishTournament = () => {
		finishTournamentAPI({
			id
		})
	}

	return (
		<>
		<Banner>Manage tournament</Banner>
		<div className='flex flex-col gap-[20px] w-[80%] mx-auto'>
			<Box className="h-[450px] !flex-row !p-0">
				<div className='h-full max-w-[450px] w-screen relative flex justify-center items-center profile-diagonal'>
					<div className='left-[20%] h-[calc(80%_-_20px)] aspect-square relative z-10'>
						<img className='left-[10px] top-[10px] object-contain absolute aspect-square h-[calc(100%_-_20px)]' 
							src={Tournament?.image ?? Logo}
						/>
					</div>
				</div>
				<div className='flex flex-col justify-between grow mx-[20%] my-[5%]'>
					<div>
						<div>
							<h1 className="text-primary font-russo text-[32px] font-[400]">{Tournament?.name}</h1>
						</div>
						<br />
						<h2 className="text-white text-[24px] font-[700]">{Tournament?.information?.slot}</h2>
					</div>
					<p className='text-white text-justify'>
						{Tournament?.information?.description}
					</p>
					<div className='flex gap-[20px]'>
						
					</div>
				</div>
				
			</Box>
			<div className='flex gap-[20px]'>
				<Box className="flex justify-center items-center flex-col">
					<h1 className='font-normal text-white text-[1.5em] font-russo'>Round {(Tournament?.information?.round ?? 1) + 1}</h1>
				</Box>
				<Box className="flex justify-center items-center flex-col">
					<h1 className='font-normal text-white text-[1.5em] font-russo'>Tournament by</h1>
					<Link to={`/profile/${Author?.id}`} className="text-primary underline">{Author?.username}</Link>
				</Box>
				<Box className="flex justify-center items-center flex-col">
					<h1 className='font-normal text-white text-[1.5em] font-russo'>{Tournament?.cashprize?.total}$</h1>
				</Box>
				<Box className="flex justify-center items-center flex-col">
					<Button red onClick={() => finishTournament()}>
						Finish tournament
					</Button>
				</Box>
			</div>
			<div className='grid grid-cols-[2fr_1fr] gap-[20px]'>
				<div className='flex flex-col gap-[20px] max-w-1/3 overflow-hidden'>
					<Box>
						<Groups tournament={id} reloadArray={[checkJoinTeam]} forceReload={reload} />
					</Box>
					<Box>
						{TournamentTeams?.filter(t => t.group === 0).map((tournamentTeam) => 
							<div key={tournamentTeam?.id} className='mx-[20px] flex gap-[20px] items-center'>
									<img className="w-[50px] aspect-square object-cover" src={tournamentTeam.team?.image !== "" ? tournamentTeam.team?.image : Logo} alt=""/>
									<p className='text-white'>{tournamentTeam.team?.name} {tournamentTeam.information?.slot && `(${tournamentTeam.team.information?.slot})`}</p>
									<Selector onChange={(ev) => setTeamGroup(tournamentTeam.id, ev)} options={[-1, ...GroupsData?.map((g, i) => i)]} optionsName={["Select a group", ...GroupsData?.map((g, i) => 'Groupe '+(i+1))]}></Selector>
							</div>
						)}
					</Box>
				</div>
				<div className='flex flex-col gap-[20px]'>
					{
						Tournament?.information?.checkin
						? <Button red onClick={toggleCheckin}>Close check-in</Button>
						: <Button onClick={toggleCheckin}>Open check-in</Button>
					}
					<Button onClick={nextRound}>Next round</Button>
					<Box>
						<h1 className='flex items-center gap-[10px] justify-center font-normal text-white text-[32px] font-russo'>
							<IconPlayer className='w-[24px] h-[24px] text-primary'/>{TournamentTeams?.length} Teams ({TournamentTeams?.filter(t => t.checked).length} checked)
						</h1>
						{TournamentTeams?.filter(t => t.checked).map((tournamentTeam) => 
							<div key={tournamentTeam?.id} className='mx-[20px]'>
								<Link className="flex gap-[20px] items-center" to={"/team/"+tournamentTeam.team?.id}>
									<img className="w-[50px] aspect-square object-cover" src={tournamentTeam.team?.image !== "" ? tournamentTeam.team?.image : Logo} alt=""/>
									<p className='text-white'>{tournamentTeam.team?.name} {tournamentTeam.information?.slot && `(${tournamentTeam.team.information?.slot})`}</p>
								</Link>
							</div>
						)}
					</Box>
					<Scoring />
					<Button to={`/tournament/${id}`}>
						Back
					</Button>
					<Button onClick={() => setShowEditTournament(true)}>
						Edit tournament
					</Button>
					<Modal show={showEditTournament} title="Edit tournament" onClose={() => setShowEditTournament(false)} action={editTournament} actionName="Edit">
					<div className='flex flex-col gap-[10px] w-full'>
							<h1 className='font-medium text-text'>Name</h1>
							<Input name="name" type="text" defaultValue={Tournament?.name}></Input>
						</div>
						<div className='flex flex-col gap-[10px] w-full'>
							<h1 className='font-medium text-text'>Link of the image</h1>
							<Input name="image" type="text" defaultValue={Tournament?.image}></Input>
						</div>
						<div className='flex flex-col gap-[10px] w-full'>
							<h1 className='font-medium text-text'>Description</h1>
							<Input name="description" type="text" defaultValue={Tournament?.information?.description}></Input>
						</div>
						<div className='flex flex-col gap-[10px] w-full'>
							<h1 className='font-medium text-text'>Twitter</h1>
							<Input name="twitter" type="text" defaultValue={Tournament?.information?.twitter}></Input>
						</div>
						<div className='flex flex-col gap-[10px] w-full'>
							<h1 className='font-medium text-text'>Instagram</h1>
							<Input name="instagram" type="text" defaultValue={Tournament?.information?.instagram}></Input>
						</div>
						<div className='flex flex-col gap-[10px] w-full'>
							<h1 className='font-medium text-text'>Twitch</h1>
							<Input name="twitch" type="text" defaultValue={Tournament?.information?.twitch}></Input>
						</div>
					</Modal>

					<Button red onClick={() => setShowDeleteTournament(true)}>Delete tournament</Button>
					<Modal show={showDeleteTournament} title={"Delete team"} onClose={() => setShowDeleteTournament(false)} action={() => {deleteTournament({id}); navigate('/tournaments/')}} actionName="Delete" urgent>
						Are you sure you want to delete the tournament "{Tournament?.name}" ?
					</Modal>
				</div>
			</div>
		</div>
		
		</>
	);
}

export default Manage;
