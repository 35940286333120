import { createStore, StoreProvider } from 'easy-peasy';
import user from './user';

const state = {
    user,
};

export const Store = (props) => {
    return <StoreProvider store={createStore(state)}>
        {props.children}
    </StoreProvider>
}