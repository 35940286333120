import React, {useEffect} from 'react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from '../components/Header';
import Footer from '../components/Footer';
import useFetchData from '../api/useFetchData';

function Root() {
  let location = useLocation().pathname;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <div className="body flex flex-col App min-h-screen bg-background font-openSans">
      <Header />
      <div className='grow flex flex-col'>
        <Outlet />
      </div>
      
      <Footer />
    </div>
  );
}

export default Root;
